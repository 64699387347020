.maadblog__header {
  text-align: center;
  font-size: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.maadblog__posts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.maadblog__post-card {
  width: 300px;
  margin: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  color: #333;
}

.maadblog__post-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.maadblog__post-title {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.maadblog__post-description {
  font-size: 14px;
  margin-bottom: 10px;
}

.maadblog__pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.maadblog__pagination-numbers {
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  background-color: #337ab7;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}
.maadblog__pagination-button,
.maadblog__pagination-button--next,
.maadblog__pagination-button--prev {
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  background-color: #e0e0e0;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

.maadblog__pagination-button:hover {
  background-color: #ccc;
}

.maadblog__pagination-numbers--active {
  background-color: #333;
  color: #fff;
}

.maadblog__pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.maadblog__posts-container {
  margin-top: 50px;
}

@media (max-width: 1100px) {
  .maadblog__pagination-numbers {
    display: none;
  }
}
