/* BusinessPodcasts.css */
.podcast__text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
}

.podcast-posts-img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin-bottom: 20px;
}

.podcast__header--primary,
.podcast__header--secondary,
.podcast__paragraph {
  text-align: center;
}

.podcast__header--primary {
  font-size: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.podcast__header--secondary {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.podcast__paragraph {
  font-size: 16px;
  margin-bottom: 20px;
}

.podcast__card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
}

.podcast__card {
  width: 300px;
  margin: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  color: #333;
}

.podcast__card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.podcast__card-header--primary {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.podcast__card-paragraph {
  font-size: 14px;
  margin-bottom: 10px;
}

.podcast__pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.podcast__pagination__button {
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  background-color: #e0e0e0;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

.podcast__pagination__button:hover {
  background-color: #ccc;
}

.podcast__pagination__active {
  background-color: #333;
  color: #fff;
}

.podcast__pagination__button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@media (max-width: 1100px) {
  .podcast__pagination__numbers {
    display: none;
  }
}
