.steps-container-right {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}

.steps-container-right__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 10px;
  max-width: 400px;
  margin-left: 50px; /* Reduce the margin */
}

.steps-container-right__header {
  text-align: center;
  color: #000000c1;
  font-size: 40px;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 2s ease-out forwards;
}

.steps-container-right__text {
  font-size: 16px;
  text-align: center;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 2s ease-out forwards;
}

.steps-container-right__image-container {
  text-align: right;
}

.steps-container-right__image {
  max-width: 300px;
  height: auto;
  animation: imageAnimation 1s ease-in-out infinite alternate;
  margin-bottom: 30px;
}

/* @keyframes slideInAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes imageAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */

@media screen and (max-width: 600px) {
  .steps-container-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 30px 20px;
  }

  .steps-container-right__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .steps-container-right__header {
    font-size: 30px;
  }

  .steps-container-right__text {
    font-size: 14px;
  }

  .steps-container-right__image {
    max-width: 200px;
  }
}
