.refund-policy {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 20px;
}

.refund-policy__title,
.refund-policy__text,
.refund-policy__section-title {
  text-align: center;
  margin: 50px;
}
