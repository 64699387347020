.cookie-policy {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 20px;
}

.cookie-policy__content-header,
.cookie-policy__header,
.cookie-policy__text {
  margin-top: 30px;
  text-align: center;
}
