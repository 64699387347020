/* Contact.css */

.contact {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh; /* or you can adjust it to your needs */
  background: url("/public/images/content-marketing.jpg") no-repeat center
    center/cover;
  color: white; /* set the text color that contrasts with your background */
}

.contact__title {
  font-size: 2em;
  font-weight: bold;
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  align-items: center;
}

.contact__subtitle {
  font-size: 1.2em;
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  margin: 10px;
  text-align: center;
}

.contact__info {
  font-size: 1.2em;
  max-width: 500px;
  color: rgb(255, 255, 255);
  margin: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
}

@media (max-width: 768px) {
  /* adjust this value as needed */
  .contact__title {
    font-size: 2em;
  }

  .contact__subtitle {
    font-size: 1.2em;
  }

  .contact__info {
    font-size: 1em;
  }
}

@media (max-width: 400px) {
  .contact {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh; /* or you can adjust it to your needs */
    color: white; /* set the text color that contrasts with your background */
  }

  .contact__title {
    margin-top: 10px;
    font-size: 20px;
  }

  .contact__subtitle {
    font-size: 10px;
    margin: 2px;
  }

  .contact__info {
    font-size: 10px;
    margin: 2px;
  }
}
