.testimonials-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.testimonials-h1 {
  color: #212529;
  text-align: center;
  margin-top: 20px;
}

.testimonial {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.testimonial h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.testimonial p {
  font-size: 16px;
  margin-bottom: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pagination-button {
  padding: 10px 15px;
  background-color: #337ab7;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.pagination-button:hover {
  background-color: #23527c;
}

.pagination-button.active {
  background-color: #23527c;
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* Ratio for 16:9 videos */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .pagination-button.page-number {
    display: none;
  }
}
