.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__logo {
  color: #6a00ffc1;
  display: flex;
  justify-self: flex-start;
  max-width: 4rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  max-height: 4rem;
  margin-right: 0px;
}

.navbar__logo__img {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 5rem;
  margin-left: 2rem;
  height: 80px;
}

.navbar__contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar__phone-number,
.navbar__email {
  font-size: 1rem;
  font-style: bold;
  color: #000;
  margin: 0;
  padding: 0;
  margin-right: 5rem;
}

.navbar__container {
  display: flex;
  justify-content: space-between; /* change from center */
  justify-items: space-between;
  height: 80px;
  width: 1300px;
  max-width: 1200px;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.navbar__menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}

.navbar__menu_active {
  display: flex;
  align-items: center;
  background: #b7b7b7;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.navbar__item {
  height: 80px;
}

.navbar__links {
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: 2rem;
  width: 100%;
  padding: 0.5rem 1rem;
  height: 0;
  display: table;
}

.navbar__links:hover {
  border-bottom: 4px solid #6a00ffc1;
  transition: all 0.2s ease-out;
  color: #6a00ffc1;
}

.fa-times {
  color: #000000;
  font-size: 2rem;
}

.fa-bars {
  color: #000000;
  font-size: 2rem;
}

.navbar__menu-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
}

.navbar__item {
  position: relative;
}

.navbar__dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 350px;
  background-color: #fff;
  padding-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: dropdownFadeIn 0.3s ease-in-out;
  max-height: 500px;
  overflow-y: scroll;
  z-index: 2;
  scrollbar-width: thin;
  scrollbar-color: #084c61 #f5f5f5;

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #084c61;
    border-radius: 20px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #065675;
  }
}

.navbar__item:hover .navbar__dropdown {
  display: block;
  color: #6a00ffc1;
}

.navbar__dropdown-item {
  list-style: none;
  margin: 20px;
  color: #6a00ffc1;
}

.navbar__dropdown-item:hover {
  background-color: #eeeeee;
  color: #6a00ffc1;
  border-radius: 4px;
  transition: 250ms;
}

.navbar__dropdown-link {
  color: #000;
  font-size: 20px;
  text-decoration: none;
}

@keyframes dropdownFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 400px) {
  .navbar__dropdown {
    min-width: 200px;
  }

  .navbar__dropdown-item {
    font-size: 10px;
    margin: 10px;
  }

  .navbar__phone-number,
  .navbar__email {
    font-size: 0.5rem;
    margin-left: 5rem;
  }
}

@media screen and (max-width: 287px) {
  .navbar__phone-number,
  .navbar__email {
    display: none;
  }
}
