.form-main__container {
  margin: 50px;
}
.form-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.513);
  color: #000000;
  border: 1px solid #000000;
}

.form-main__title {
  margin-bottom: 40px;
  color: black;
}

.form-main__message {
  margin-top: 20px;
  color: black;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.form-main__group {
  margin-bottom: 20px;
}

.form-main__group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-main__group input,
.form-main__group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 4px;
}

.form-main__group-row {
  display: flex;
  justify-content: space-between;
}

.form-main__group-item {
  flex-basis: 48%;
}

.form-main__button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #f5f5f5;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 1s, color 2s;
}

.form-main__button:hover {
  background-color: #6a00ff;
  color: white;
}
