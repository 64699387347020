.hero {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: space-between;
  flex-direction: row;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  overflow: hidden;
}

.hero__background-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
}

.hero__content {
  height: 100vh;
  display: flex;
  flex-direction: row;
  max-width: 1200px;
}

.hero__content-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 600px;
  object-fit: auto;
  margin: 10px;
  max-width: 600px;
}

.hero__content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  object-fit: contain;
  margin: 10px;
}

.hero__heading {
  margin-top: 8px;
  color: #ffffffc1;
  font-size: 4rem;
  font-weight: bold;
  font-style: normal;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.hero__sub-heading {
  margin-top: 8px;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 100;
}

.hero__text {
  margin-top: 8px;
  color: #ffffff;
  font-size: 1rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 100;
  align-items: center;
  text-align: center;
}

.hero__buttons {
  margin: 10px;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 1100px) {
  .hero__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .hero__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero__heading {
    font-size: 2rem;
  }

  .hero__sub-heading {
    font-size: 1rem;
  }
  .hero__text {
    font-size: 16px;
    word-spacing: -0.1em;
  }
}

@media screen and (max-width: 700px) {
  .hero__background-image {
    width: 100vw;
  }

  .hero__heading {
    font-size: 1.5rem;
  }

  .hero__sub-heading {
    font-size: 20px;
  }
  .hero__text {
    font-size: 16px;
  }
}
