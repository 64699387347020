.blog-post {
  display: flex;
  justify-content: center;
  align-items: column;
  flex-direction: column;
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.blog-post__title {
  font-size: 40px;
  font-weight: 700;
  color: #212529;
  text-align: center;
  margin-bottom: 50px;
}

.blog-post__image {
  width: 100%;
  max-width: 600px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.blog-post__info {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
}

.blog-post__author {
  font-weight: bold;
}

.blog-post__date {
  margin-left: 10px;
  color: #888;
}

.blog-post__video {
  aspect-ratio: 16/9;
  display: block;
  margin: 0 auto;
  padding-top: 20px;
}

.blog-post__subtitle {
  margin-top: 15px;
  font-size: 20px;
  color: #333;
  font-weight: 500;
}

p {
  margin-top: 15px;
  line-height: 1.5;
  color: #555;
  text-align: justify;
}

.blog-post__share {
  margin-top: 20px;
}

.blog-post__share-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.blog-post__share-button--facebook {
  background-color: #3b5998;
}

.blog-post__share-button--linkedin {
  background-color: #0077b5;
}

.blog-post__share-button--twitter {
  background-color: #1da1f2;
}

.blog-post__share-button--instagram {
  background-color: #c13584;
}

.blog-post__share-button--email {
  background-color: #ff0000;
}

.blog-post__share-button i {
  margin-right: 5px;
}

.blog-post__share-button:hover {
  opacity: 0.8;
}

.podcast-post__links {
  display: flex;
  flex-direction: column;
}

.podcast-post__logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.podcast-post__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  align-content: center;
}

@media (max-width: 768px) {
  .blog-post__video {
    padding: 0px;
  }
}
