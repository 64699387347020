.section {
  max-width: 1200px;
  margin: 50px auto;
  text-align: center;
  padding: 0 40px;
}

.section__title {
  font-size: 2rem;
  margin: 50px;
  text-align: center;
}

.section__text {
  margin-bottom: 1.5rem; /* Adding some spacing after each paragraph */
  line-height: 1.6; /* Increasing the line height for better readability */
  text-align: center; /* Justifying the text */
}
