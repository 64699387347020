.dashboard_welcome {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;

}


.dashboard_image {
    display: flex;
    width: 50%;
    height: 50%;
    object-fit: cover;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 20px;
    justify-content: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.dashboard_buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
    color: black;
    font-size: large;
}

.dashboard_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 50px;
    border-radius: 10px;
    background-color: #f0f0f0;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}