.video-container {
  max-width: 1000px;
  width: 50vw;
  height: 50vh;
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  border: rgb(182, 182, 182) 5px solid;
  border-radius: 20px;
}

.video-iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .video-container {
    max-width: 90%;
  }
}
