.steps-container-left {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
  opacity: 0;
  animation: slideInAnimation 1s ease-in-out forwards;
}

.steps-container-left__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 10px;
  max-width: 400px; /* Adjust the size as needed */
  margin-right: 50px; /* Reduce the margin */
}

.steps-container-left__header {
  text-align: center;
  color: #000000c1;
  font-size: 40px; /* Reduce the font size */
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 2s ease-out forwards;
}

.steps-container-left__text {
  font-size: 16px; /* Reduce the font size */
  text-align: center;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 2s ease-out forwards;
}

.steps-container-left__image-container {
  text-align: right;
}

.steps-container-left__image {
  max-width: 300px; /* Adjust the size as needed */
  height: auto;
  animation: imageAnimation 1s ease-in-out infinite alternate;
  margin-bottom: 30px; /* Reduce the margin */
}

@keyframes imageAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes slideInAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 600px) {
  /* Adjust the breakpoint to target iPhone SE */
  .steps-container-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto; /* Remove the fixed height */
    padding: 30px 20px; /* Adjust the padding */
  }

  .steps-container-left__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 30px; /* Reduce the margin */
  }

  .steps-container-left__header {
    font-size: 30px; /* Reduce the font size */
  }

  .steps-container-left__text {
    font-size: 14px; /* Reduce the font size */
  }

  .steps-container-left__image {
    max-width: 200px; /* Reduce the image size */
  }
}
