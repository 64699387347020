.form-guide {
  background-color: rgba(160, 160, 160, 0.608);
  color: black;
  max-width: 100%;
  min-width: 1vw;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.form-guide__button {
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--primary);
  border: 1px solid white;
  color: #ffffff;
  transition: all 0.3s ease-out;
  font-size: 1rem;
}

.form-guide__button:hover {
  background: white;
  color: #242424;
  transition: all 0.3s ease-out;
}

.form-guide__title {
  margin-bottom: 5px;
  color: black;
}

.form-guide__fieldset {
  margin-bottom: 20px;
  width: 100%;
  border: none;
}

.form-guide__input,
textarea {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
}

.consent-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.form-guide__message {
  margin-top: 20px;
  color: white;
  font-weight: bold;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  transition: background-color 1s, color 2s;
}
