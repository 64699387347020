.home__section {
  width: 100%;
  background-color: white;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  max-width: 1200px;
  font-family: "Cabinet-Grotesk", sans-serif;
}

.home__section-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home__section-header {
  font-size: 50px;
  font-weight: 700;
  color: #212529;
  text-align: center;
  font-family: "Cabinet-Grotesk", sans-serif;
}

.home__section-text {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
  color: #212529;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .home__section {
    padding: 30px;
  }
  .home__section-header {
    font-size: 30px;
  }
}
