.cards {
  /* padding: 4rem; */
  background: transparent;
}

.cards__title {
  text-align: center;
  color: #000000;
  font-size: 50px;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

@media only screen and (min-width: 1200px) {
  .cards__container {
    width: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}
