.terms {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 20px;
}

.terms__heading,
.terms__text,
.terms__sub-heading {
  text-align: center;
  margin: 50px;
}
