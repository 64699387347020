.footer {
  width: 100%;
  background-color: #272727c1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin-top: 40px;
}

.footer__media {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.footer__logo {
  margin-top: 20px;
}

.footer__logo-img {
  width: 100%;
  max-width: 100px;
}

.footer__logo-link {
  color: #fff;
  font-size: 24px;
  text-decoration: none;
  max-width: 100px;
}

.footer__link-items {
  display: flex;
  flex-direction: column;
  align-items: center; /* Updated */
  margin-bottom: 16px; /* Updated */
}

.footer__link-item {
  color: #fff;
  text-decoration: none;
  margin-bottom: 8px;
  transition: color 0.3s ease;
}

.footer__link-item:hover {
  color: #e9e9e9;
}

.footer__rights {
  color: #fff;
  margin-top: 16px;
}

.footer__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.footer__icon-link {
  color: #fff;
  font-size: 24px;
  margin-right: 8px;
  transition: color 0.3s ease;
}

.footer__icon-link:hover {
  color: #e9e9e9;
}

.footer__section {
  width: 300px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__section h2 {
  color: #fff;
  margin-bottom: 16px;
}

.footer__section p {
  color: #fff;
  margin-bottom: 8px;
  align-content: center;
  text-align: center;
}

.footer__email-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.footer__input {
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  border: none;
  border-radius: 4px;
  outline: none;
}

.footer__btn {
  padding: 8px 16px;
  background-color: #fff;
  color: #6a00ff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.footer__btn:hover {
  background-color: #e9e9e9;
}

.footer__weather {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  color: white;
}

@media screen and (max-width: 768px) {
  .footer__items {
    flex-direction: column;
    align-items: center;
  }

  .footer__media {
    width: 100%;
    margin-bottom: 24px;
  }

  .footer__section {
    width: 100%;
    text-align: center;
  }
}
