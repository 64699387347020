.home-package {
  padding: 4rem;
  background: transparent;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 1s ease-out forwards;
}

.home-package__title {
  text-align: center;
  color: #030303c1;
  font-size: 50px;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 1s ease-out forwards;
}

.home-package__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.home-package__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.home-package__items {
  margin-bottom: 24px;
  max-width: 100%;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (min-width: 1024px) {
  .home-package__items {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
  }
}
