.testimonial-slider {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.testimonial-slider__item {
  opacity: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
}

.testimonial-slider__item--active {
  opacity: 1;
  display: block;
}

.testimonial-slider__quote {
  font-size: 2em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: black;
}

.testimonial-slider__author {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  margin-bottom: 10px;
}

.testimonial-slider__icon {
  margin-right: 10px;
}

.testimonial-slider__designation {
  font-size: 1em;
  color: grey;
}

.testimonial-slider__dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-slider__dot {
  width: 10px;
  height: 10px;
  background-color: #ddd;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;
}

.testimonial-slider__dot--active {
  background-color: #333;
}

@media (max-width: 768px) {
  .testimonial-slider {
    margin: 50px;
  }
  .testimonial-slider__quote {
    font-size: 1em;
  }

  .testimonial-slider__author {
    font-size: 1.2em;
  }

  .testimonial-slider {
    padding: 10px;
  }

  .testimonial-slider__designation {
    font-size: 0.8em;
    margin: 1rem;
  }

  .testimonial-slider__dot {
    width: 8px;
    height: 8px;
  }
}
