.recommendation {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.recommendation__title {
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
  justify-content: center;
}
.recommendation__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.recommendation__card {
  flex-basis: calc(
    33.33% - 20px
  ); /* Adjusts the width of the cards to fit 3 per row with 20px gap */
  margin: 10px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  position: relative; /* To align button at the bottom */
  display: flex;
  flex-direction: column; /* To structure content vertically */
  border-radius: 10px;
}

.recommendation__card-title {
  font-size: 16px;
  margin-bottom: 10px;
  flex-grow: 1; /* To push the button to bottom */
}

.recommendation__card-image {
  width: 100%;
  height: auto; /* Adjust height to maintain image aspect ratio */
  object-fit: cover;
}

.recommendation__button-container {
  display: flex;
  justify-content: center;
  margin-top: auto; /* Push the container to the bottom */
}

.recommendation__button {
  padding: 10px 20px;
  background-color: #337ab7;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.recommendation__button:hover {
  background-color: #23527c;
}

.recommendation__card-link {
  text-decoration: none;
  color: black;
  margin-top: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.recommendation__card-link:hover {
  background-color: #ccc;
}

@media (max-width: 768px) {
  .recommendation__row {
    flex-direction: column;
  }
  .recommendation__card {
    flex-basis: 100%;
    margin: 10px 0;
  }
  .recommendation {
    padding: 10px;
  }
}
