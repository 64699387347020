.button {
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid;
  cursor: pointer;
  margin-top: 10px;
}

.button--primary {
  background-color: var(--primary);
  color: white;
  border: 1px solid white;
}

.button--outline {
  background-color: transparent;
  color: #ffffff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.button--medium {
  padding: 8px 20px;
  font-size: 1rem;
}

.button--large {
  padding: 12px 26px;
  font-size: 1rem;
}

.button--medium:hover,
.button--large:hover {
  background: white;
  color: #242424;
  transition: all 0.3s ease-out;
}

@media screen and (max-width: 700px) {
  .button {
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }

  .button--primary {
    background-color: var(--primary);
    color: white;
    border: 1px solid var(--primary);
  }

  .button--medium {
    padding: 8px 20ox;
    font-size: 0.5rem;
  }

  .button--large {
    padding: 12px 26px;
  }

  .button--medium:hover,
  .button--large:hover {
    background: white;
    color: #242424;
    transition: all 0.3s ease-out;
  }
}
