.services-list {
  padding: 1rem;
  background: transparent;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 1s ease-out forwards;
}

.services-list__title {
  text-align: center;
  color: #000000c1;
  font-size: 50px;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 1s ease-out forwards;
}

.services-list__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1500px;
  width: 90%;
  margin: 0 auto;
}

.services-list__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.services-list__items {
  margin-bottom: 24px;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
    margin-bottom: 2rem;
  }
}
